<template>
<!-- TODO: Перепроверить стили при печати -->
  <div class="bar-code-wrapper page">
    <el-card class="box-card box-control box-control-flex box-control-flex-column">
      <div class="display-flex">
        <label class="main-label">
          <span class="main-label__title">Тип инвентаризации</span>

          <el-select
            v-model="inventoryType"
            placeholder="Выберите тип"
            @change="loadAllProducts"
          >
            <el-option
              v-for="item in inventoryTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </label>

        <label
          v-if="inventoryType === 2"
          class="main-label"
        >
          <span class="main-label__title">Категории товаров</span>

          <el-select
            v-model="selectedCategoryId"
            placeholder="Выберите категории товаров"
            @change="loadProductsById"
          >
            <el-option
              v-for="item in getDataCategories"
              :key="item.value"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </label>
      </div>

      <el-button
        class="margin-top-10 el-button-h-40"
        plain
        type="primary"
        :disabled="isEmpty(loadedProductsDataFiltered)"
        @click="print"
      >
        <span>Печать</span>
      </el-button>
    </el-card>

    <el-card v-if="!isEmpty(loadedProductsDataFiltered)">
      <table
        id="print"
        class="bar-code-table"
        border="1"
      >
        <thead>
          <tr>
            <th width="25%">Категория</th>
            <th width="45%">Название</th>
            <th width="10%">Штрихкод</th>
            <th width="10%">Цена</th>
            <th width="5%">Заявленное количество</th>
            <th width="5%">Фактическое количество</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="product in loadedProductsDataFiltered"
            :key="product.id"
          >
            <td>{{ product.categoriesName }}</td>
            <td>{{ product.title }}</td>
            <td>{{ product.code }}</td>
            <td>{{ getPriceToFormat(product.price) }} р.</td>
            <td>{{ product.count }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </el-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { getPriceToFormat } from '@/helper'

export default {
  name: 'Inventory',
  data () {
    return {
      inventoryType: null,
      inventoryTypeList: [
        {
          name: 'Для всех товаров',
          id: 1
        },
        {
          name: 'Для выбранной категории',
          id: 2
        }
      ],
      loadedProductsData: [],
      selectedCategoryId: null,
      selectedProductsId: null,
      isEmpty
    }
  },
  computed: {
    ...mapGetters('categories', { getDataCategories: 'getData' }),
    ...mapGetters('products', { getDataProducts: 'getData' }),
    loadedProductsDataFiltered () {
      const getCategoryById = (findId) => this.getDataCategories.filter(({ id }) => id === findId)[0]

      return this.loadedProductsData.reduce((acc, product) => {
        if (!product.count) {
          return acc
        }

        acc.push({
          ...product,
          categoriesName: getCategoryById(product.categories)?.title || 'Нет категории'
        })

        return acc
      }, [])
    }
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('categories', { loadDataCategories: 'loadData' }),
    ...mapActions('products', {
      loadDataProducts: 'loadData',
      getDataByCategoriesId: 'getDataByCategoriesId'
    }),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataCategories()
      await this.loadDataProducts()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
    getPriceToFormat,
    async loadAllProducts () {
      if (this.inventoryType === 1) {
        this.loadedProductsData = this.getDataProducts
      }
    },
    async loadProductsById () {
      this.loadedProductsData = await this.getDataByCategoriesId(this.selectedCategoryId) || []
    },
    print () {
      const myWindow = window.open("", "printMe", "height=600, width=1000")

      // TODO: КОСТЫЛЬ
      myWindow.document.write("<style>.bar-code-table { width: 100%; font-size: 12px; border-collapse: collapse; border: 1px solid #000; } th { font-size: 12px; text-align: left; } th { padding: 4px; } td { padding: 4px; }</style>")
      myWindow.document.write(document.getElementById('print').outerHTML)
      myWindow.document.close()
      myWindow.focus()
      myWindow.print()
      myWindow.close()

      return true;
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />